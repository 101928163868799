import * as turf from '@turf/turf'

export function geojsonToTurf(geojson) {
    // if geojson is null, throw an error
    if (geojson === null) {
        throw new Error('geojson is null')
    }

    // if geojson is a string, parse it
    if (typeof geojson === 'string') {
        geojson = JSON.parse(geojson)
    }

    // if geojson is a point, return a turf point
    if (geojson.geometry?.type === 'Point') {
        return turf.point(geojson.geometry.coordinates)
    }

    if(geojson.type === 'Polygon' || geojson.geometry?.type == 'Polygon') {
        return turf.polygon(geojson.coordinates ? geojson.coordinates : geojson.geometry.coordinates)
    }

    if (geojson.type === 'MultiPolygon' || geojson.geometry?.type == 'MultiPolygon') {
        let startingRoot = geojson.type === 'MultiPolygon' ? geojson.coordinates[0][0] : geojson.geometry.coordinates[0][0]
        return turf.multiPolygon([[startingRoot]])
    }

    if (geojson.type === 'MultiLineString' || geojson.geometry?.type == 'MultiLineString') {
        let startingRoot = geojson.type === 'MultiLineString' ? geojson.coordinates[0] : geojson.geometry.coordinates[0]
        return turf.multiLineString([startingRoot])
    }

    if(geojson.type === 'GeometryCollection') {
        if(geojson.geometries.length >= 1) {
            return geojsonToTurf(geojson.geometries[0])
        } else {
            return null
        }
    }

}