<template>
  <div :id="id" class="map" :key="id"></div>
</template>
<script>
import L from 'leaflet';
import 'leaflet-compass'
import 'leaflet-compass/dist/leaflet-compass.min.css';
import '@geoman-io/leaflet-geoman-free'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
import { useGeolocation } from '@vueuse/core';
import { watchEffect, ref } from 'vue'
import { point, booleanPointInPolygon } from '@turf/turf'
import { geojsonToTurf } from '@/utils/polygons'

export default {
  props: {
    customLayers: { type: Array, default: () => [] },
    polygons: { type: Array, default: () => [] },
    markers: { type: Array, default: () => [] },
    geolocate: { type: Boolean, default: false },
    zoomControl: { type: Boolean, default: false },
    layerControl: { type: Boolean, default: false },
    locateControl: { type: Boolean, default: false },
    fullscreenControl: { type: Boolean, default: false },
    orientation: { type: Boolean, default: false },
    drawing: { type: Boolean, default: false },
    center: { type: Array, default: () => null },
    fitBounds: { type: Boolean, default: true },
    currentLayer: { type: Number, default: 0 },
    leafletConfig: { type: Object, default: {} },
  },
  emits: [
    'location',
    'click',
    'draw:created',
    'draw:updated',
    'draw:removed',
    'fullscreen',
    'layer:change'
  ],
  data: () => ({
    map: null,
    id: 'map' + Date.now(),
    timeout: null,
    baseLayers: [],
    markersL: []
  }),
  setup() {
    let zoomed = ref(false)
    const { coords, locatedAt, error, resume, pause } = useGeolocation({
      enableHighAccuracy: true
    })
    return { coords, locatedAt, error, resume, pause, zoomed }
  },
  watch: {
    polygons: {
      handler: function () {
        this.drawPolygons(this.polygonsArray)
      },
      deep: true
    },
    markers: {
      handler: function () {
        if (this.markers.length > 0) {
          console.log('Markers', this.markers);
          this.drawPins(this.markers)
        }
      },
      deep: true
    },
    currentLayer: {
      handler: function () {
        this.initializeLayers(this.customLayers)
      },
      deep: true
    },
    customLayers: {
      handler: function () {
        this.initializeLayers(this.customLayers)
      },
      deep: true
    }
  },
  methods: {
    drawMarker(lat, lng, style = null, onClick = null) {
      if (!style) {
        style = { radius: 10, color: "#ffffff", fillColor: "#0066ff", fillOpacity: 1 }
      }
      if (this.marker) {
        this.map.removeLayer(this.marker)
      }
      let marker = L.circleMarker([lat, lng], style).addTo(this.map)
      if (onClick) {
        marker.on('click', onClick)
      }
      return marker
    },
    drawPolygons(polygons) {
      if (!Array.isArray(polygons)) {
        polygons = [polygons]
      }

      // remove old polygons
      this.map.eachLayer(layer => {
        if (layer instanceof L.GeoJSON) {
          this.map.removeLayer(layer)
        }
      })

      if (polygons.length > 0) {
        let mapReference = this.map

        polygons.forEach(polygon => {
          if(!polygon.polygon) return;
          if (polygon.polygon.type === 'FeatureCollection' && polygon.polygon.features.length === 0) return;
          
          if (polygon.polygon.geometry?.type === 'Point') {
            this.drawMarker(polygon.polygon.geometry.coordinates[1], polygon.polygon.geometry.coordinates[0], polygon.style, polygon.onClick)
          } else {
            console.log(polygon.polygon)

            const geojson = L.geoJson(polygon.polygon, {
              style: polygon.style,
              interactive: !polygon.notInteractive,
              pointToLayer: function(feature, latlng) {
                return L.circleMarker(latlng, polygon.style);
              },
              onEachFeature: function (feature, layer) {
                // if feature is LineString and has onClick, execute onClick
                //console.log(feature)
                if (feature.type === 'LineString' || feature.type == 'MultiLineString' && polygon.onClick) {
                  layer.on('click', polygon.onClick)                  
                } else {
                  layer.on('click', function () {
                    mapReference.fitBounds(layer.getBounds())
                  })
                }

                if(polygon.tooltip) {
                  let tooltip = L.tooltip();
                  tooltip.setContent(polygon.tooltip);
                  tooltip.setLatLng(layer.getBounds().getCenter());
                  layer.bindTooltip(tooltip);
                }
              }
            })

            geojson.addTo(this.map);

            // foreach feature
            geojson.eachLayer(layer => {
              // if feature is LineString and has onClick, execute onClick
              if (layer.feature.geometry.type === 'LineString' && polygon.onClick) {
                layer.on('click', polygon.onClick)
              }
            })
          }

        })

        setTimeout(() => {
          try {
            // invalidate size on timeout to fix leaflet bug
            this.map.invalidateSize();

            // check if there is a polygon with the focus attribute
            let focusPolygon = polygons.find(polygon => polygon.focus)

            // if there is a polygon with the focus attribute, fit the map to the bounds of that polygon
            if (focusPolygon) {
              this.map.flyToBounds(L.geoJson(focusPolygon.polygon).getBounds());
            } else if (this.fitBounds) {
              let allBounds = L.latLngBounds();

              polygons.forEach(polygon => {
                allBounds.extend(L.geoJson(polygon.polygon).getBounds());
              });

              this.map.fitBounds(allBounds);
            }
          } catch (error) {
            console.log(error)
          }
        }, 100)
      }
    },
    drawPins(markers) {
      if (markers.length > 0) {
        console.log('drawing markers...');
        const labels = L.featureGroup().addTo(this.map);
        markers.forEach((marker) => {
          this.markersL.push(L.marker([marker.centroid.geometry.coordinates[1], marker.centroid.geometry.coordinates[0]], {
            icon: L.divIcon({
              iconSize: "auto",
              html: marker.html,
            })
          }).addTo(labels))
        })

        this.map.addLayer(labels)
      }
    },
    initializeLayers() {
      // remove old layers
      this.map.eachLayer(layer => {
        if (layer instanceof L.TileLayer) {
          this.map.removeLayer(layer)
        }
      })

      this.baseLayers = [];

      // foreach custom layer, create a layer group
      this.customLayers.forEach((layer, index) => {
        let group = L.layerGroup();
  
        // add each layer in the group to the map
        for (let i = 0; i < layer.layers.length; i++) {
          let layerDetails = layer.layers[i];
          let tileLayer = L.tileLayer(layerDetails.url, layerDetails.config);
          tileLayer.addTo(group);
  
          // If this is the first layer, bring it to the front
          if (i === 0) {
            tileLayer.bringToFront();
          }
        }
  
        // add the group to the baseLayers
        this.baseLayers.push(group)
      });



      if (Object.keys(this.baseLayers).length > 0) {
        this.baseLayers[this.currentLayer].addTo(this.map);
      }
    }
  },
  computed: {
    polygonsArray() {
      // get polygons and remove empty polygons
      let result = this.polygons.map(polygon => {
        if (typeof polygon == 'string') {
          return {
            polygon: polygon,
            onClick: null,
            focus: false,
            notInteractive: false,
            style: null,
            tooltip: null,
            title: null
          }
        }

        return {
          polygon: polygon.polygon ? polygon.polygon : null,
          onClick: polygon.onClick,
          focus: polygon.focus,
          notInteractive: polygon.notInteractive,
          style: polygon.style,
          tooltip: polygon.tooltip,
          title: polygon.title
        }
      }).filter(polygon => polygon.polygon)


      // parse polygons and flatten array
      result = result.map(polygon => {
        if (typeof polygon.polygon == 'string') {
          polygon.polygon = JSON.parse(polygon.polygon)
        }

        return polygon
      }).flat()

      return result
    }
  },
  mounted() {
    if (this.geolocate) this.resume();

    let container = L.DomUtil.get(this.id);
    if (container != null) {
      container._leaflet_id = null;
    }

    this.map = L.map(this.id, {
      ...this.leafletConfig,
      center: this.center,
      zoom: 16,
      zoomControl: false,
      minZoom: this.leafletConfig.minZoom ? this.leafletConfig.minZoom : 18,
      maxZoom: this.leafletConfig.maxZoom ? this.leafletConfig.maxZoom : 22,
      attributionControl: false,
    });

    if (this.orientation) {
      L.control.compass({
        position: 'topright',
        autoActive: true,
        showDigit: false,
      }).addTo(this.map);
    }

    // layer selection
    this.baseLayers = [];

    this.initializeLayers(this.customLayers);

    if (this.fullscreenControl) {
      L.Control.Fullscreen = L.Control.extend({
        onAdd: () => {
          let cont = L.DomUtil.create('div', 'leaflet-bar leaflet-control-fullscreen-box');
          var btn = L.DomUtil.create('a', 'leaflet-control-fullscreen');
          btn.addEventListener('click', () => {
            this.$emit('fullscreen')
          })
          cont.appendChild(btn)
          return cont;
        },
        onRemove: function () { }
      })
      L.Control.fullscreen = function (opts) {
        return new L.Control.Fullscreen(opts);
      }
      L.Control.fullscreen({ position: 'bottomright' }).addTo(this.map);
    }

    if (this.locateControl) {
      L.Control.Geolocate = L.Control.extend({
        onAdd: function () {
          let cont = L.DomUtil.create('div', 'leaflet-bar leaflet-control-geolocate-box');
          var btn = L.DomUtil.create('a', 'leaflet-control-geolocate');
          btn.addEventListener('click', () => {
            console.log('geolocate!!!')
          });
          cont.appendChild(btn)
          return cont;
        },
        onRemove: function () {
        }
      });
      L.Control.geolocate = function (opts) {
        return new L.Control.Geolocate(opts);
      }
      L.Control.geolocate({ position: 'bottomright' }).addTo(this.map);
    }

    if (this.zoomControl) {
      L.control.zoom({
        position: 'bottomright',
        zoomInText: '',
        zoomOutText: ''
      }).addTo(this.map);
    }

    if (this.layerControl) {
      L.control.layers(this.baseLayers, null, {
        collapsed: false,
        position: 'bottomleft'
      }).addTo(this.map);
    }

    this.map.on('baselayerchange', (e) => {
      // emit e.name to integer
      this.$emit('layer:change', parseInt(e.name))
    })

    // leaflet bug
    this.map.invalidateSize();

    this.drawPolygons(this.polygonsArray)

    if (this.geolocate) {
      let circle, marker;

      watchEffect(() => {
        let position = [this.coords.latitude, this.coords.longitude]

        // check if position is valid
        if (position[0] == 'Infinity' || position[1] == 'Infinity') return;

        let pointFeature = {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": position
          }
        }
        if (this.coords && this.coords.longitude != 'Infinity') {
          if (marker) {
            this.map.removeLayer(marker)
            this.map.removeLayer(circle)
          }
          if (marker) {
            this.map.removeLayer(marker)
            this.map.removeLayer(circle)
          }

          marker = this.drawMarker(this.coords.latitude, this.coords.longitude)
          circle = L.circle([this.coords.latitude, this.coords.longitude], {
            radius: this.coords.accuracy,
            fillOpacity: 0.1,
            weight: 1,
            interactive: false
          }).addTo(this.map)

          if (!this.zoomed) {
            //this.map.fitBounds(circle.getBounds())
            this.zoomed = true
            this.$emit('location', pointFeature)
          }
        }
      })
    }

    if (this.drawing) {
      this.map.pm.addControls({
        position: 'topleft',
        drawRectangle: false,
        drawCircleMarker: false,
        drawCircle: false,
        drawText: false
      })

      // the leaflet id is taken in order to identify the polygon
      this.map.on('pm:create', (e) => {
        let geojson = e.layer.toGeoJSON()
        geojson.properties.id = e.layer._leaflet_id
        this.$emit('draw:created', geojson)

        e.layer.on('pm:update', (e) => {
          let geojson = e.layer.toGeoJSON()
          geojson.properties.id = e.layer._leaflet_id
          this.$emit('draw:updated', geojson)
        })

        e.layer.on('pm:cut', (e) => {
          let geojson = e.layer.toGeoJSON()
          geojson.properties.id = e.layer._leaflet_id
          this.$emit('draw:updated', geojson)
        })
      })

      this.map.on('pm:remove', (e) => {
        this.$emit('draw:removed', e.layer._leaflet_id)
      })
    }

    // listeners
    this.map.on('click', (e) => {
      let clickedPoint = point([e.latlng.lng, e.latlng.lat]);
      let intersectedPolygons = [];

      this.polygonsArray.forEach(polygon => {
        if (!polygon.polygon) return;

        let turfPolygon = geojsonToTurf(polygon.polygon);
        if (!turfPolygon) return;

        // check if polygon is not a point
        if (turfPolygon.geometry.type === 'Point') return;

        let isInside = turfPolygon.geometry.type === 'MultiLineString' ? false : booleanPointInPolygon(clickedPoint, turfPolygon);
        
        if (isInside) {
          intersectedPolygons.push(polygon);
        }
      });

      if (intersectedPolygons.length === 1 && intersectedPolygons[0].onClick) {
        intersectedPolygons[0].onClick();
      } else if (intersectedPolygons.length > 1) {
        this.$emit('click', { event: e, polygons: intersectedPolygons });
      }
    });

    this.map.on('zoomend', (e) => {
      this.markersL.forEach(marker => {
        if (this.map.getZoom() > this.markers[0].options.minZoom) {
          marker.addTo(this.map);
        } else {
          marker.remove();
        }
      })
    });
  },
  unmounted() {
    this.pause() //stop geolocation

    if (this.map && this.map.remove) {
      try {
        this.map.off()
        this.map.remove()
      } catch (e) {
        console.log('Unmount Map Error', e)
      }
    }
  },
}
</script>
<style scoped>
.map {
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: #EFEFEF;
}
</style>

<style>
.leaflet-tooltip-pane > * {
  text-align: left;
}
</style>